<template>
  <div id="app">
    <Content/>
  </div>
</template>

<script>
import Content from './components/Content.vue'

export default {
  name: 'App',
  components: {
    Content
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;1,300&display=swap');
#app {
  font-family: 'Nunito', sans-serif !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
  background: #F2F8FF;
  /* margin-top: 60px; */
}
/* *{
  font-family: "Nunito";
} */

</style>
