import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'
import * as boxicons from 'boxicons'

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax)
Vue.use(boxicons)
import 'bootstrap'; import 'bootstrap/dist/css/bootstrap.min.css';

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
