
<template>

    <div class="mainContainer">
        <div class="center examplex" v-if="true">
          <vs-navbar center-collapsed v-model="active">
            <template #left>
              <img :src="require('@/assets/logo.png')" alt="">
            </template>
            <vs-navbar-item class="display-table" :active="active == 'guide'" id="guide">
              <i class='display-table-cell bx bx-vial mr-2 bx-sm'></i>
              <span class="display-table-cell">Lab</span>
            </vs-navbar-item>
            <vs-navbar-item disabled class="display-table" :active="active == 'docs'" id="docs">
              <i class='display-table-cell bx bx-book-reader mr-2 bx-sm'></i>
              <span class="display-table-cell">Doc</span>
            </vs-navbar-item>
            <vs-navbar-item disabled class="display-table" :active="active == 'components'" id="components">
              <i class='display-table-cell bx bx-info-circle mr-2 bx-sm'></i>
              <span class="display-table-cell">About us</span>
            </vs-navbar-item>
            <vs-navbar-item disabled class="display-table" :active="active == 'contact'" id="contact">
              <i class='display-table-cell bx bx-message-detail mr-2 bx-sm'></i>
              <span class="display-table-cell">Contact us</span>
            </vs-navbar-item>
            <template #right>
              <a href="https://www.buymeacoffee.com/skiod" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style="height: 60px !important;width: 217px !important;" ></a>
            </template>
          </vs-navbar>
        </div>
      <div class="mainContainer__parent">
        <div class="body-container">
          <Generator v-if="active=='guide'" />
          <!-- <ContactUs v-if="active == 'contact'" />  -->
          <!-- <Home v-if="active == 'home'"/>
          <ContactUs v-if="active == 'contact'" /> -->
        </div>
      </div>
    </div>
</template>
<script>
    // import ContactUs from './ContactUs.vue'
    import Generator from './Generator.vue'
    export default {
      data:() => ({
        active: 'guide'
      }),
      components : {
        // ContactUs,
        Generator
      }
    }
</script>
<style lang="css">
  .vs-navbar__line{
    border-radius: 5px 5px 0 0;
    height: 5px;
  }
  .vs-navbar__item .active{
    font-weight: bold;
    /* font-size: 16px; */
  }
  .vs-navbar__item{
    margin-right:10px !important;
  }
  .vs-navbar__item:last-child{
    margin-right:0 !important;
  }
  .vs-navbar-content{
    position:fixed !important;
    top:0;
    left:0;
    -moz-box-shadow: 0 4px 4px rgba(37, 88, 255, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(37, 88, 255, 0.2);
    box-shadow: 0 4px 4px rgba(37, 88, 255, 0.2);
  }
  /* .vs-navbar-content:after {
    content:"";
    position:absolute;
    width:100%;
    bottom:1px;
    z-index:-1;
    transform:scale(.9);
    box-shadow: 0px 0px 8px 2px #000000;
  } */
  .vs-navbar__left{
    width:200px;
  }
  .vs-navbar__left img{
    width:100%;
  }
  .directory-list{
    margin: 0;
    padding: 0 20px;
  }

  .directory-list ul {
    margin-left: 10px;
    padding-left: 20px;
    border-left: 1px dashed #ddd;
  }

  .directory-list li {
    list-style: none;
    color: #2B4D75;
    font-size: 18px;
    /* font-style: italic; */
    font-weight: normal;
  }

  .directory-list a {
    border-bottom: 1px solid transparent;
    color: #2B4D75;
    text-decoration: none;
    transition: all 0.2s ease;
  }

  .directory-list a:hover {
    border-color: #eee;
    color: #000;
  }

  .directory-list .folder,
  .directory-list .folder > a {
    color: #2B4D75;
    font-weight: bold;
    font-size:18px;
    /* font-weight */
  }


  /* The icons
  -------------------------------------------------------------- */

  .directory-list li:before {
    margin-right: 10px;
    content: "";
    height: 20px;
    vertical-align: middle;
    width: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    /* file icon by default */
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path fill='lightgrey' d='M85.714,42.857V87.5c0,1.487-0.521,2.752-1.562,3.794c-1.042,1.041-2.308,1.562-3.795,1.562H19.643 c-1.488,0-2.753-0.521-3.794-1.562c-1.042-1.042-1.562-2.307-1.562-3.794v-75c0-1.487,0.521-2.752,1.562-3.794 c1.041-1.041,2.306-1.562,3.794-1.562H50V37.5c0,1.488,0.521,2.753,1.562,3.795s2.307,1.562,3.795,1.562H85.714z M85.546,35.714 H57.143V7.311c3.05,0.558,5.505,1.767,7.366,3.627l17.41,17.411C83.78,30.209,84.989,32.665,85.546,35.714z' /></svg>");
    background-position: center 2px;
    background-size: 60% auto;
  }

  .directory-list li.folder:before {
    /* folder icon if folder class is specified */
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/3143/3143005.svg");
    /* background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path fill='lightblue' d='M96.429,37.5v39.286c0,3.423-1.228,6.361-3.684,8.817c-2.455,2.455-5.395,3.683-8.816,3.683H16.071 c-3.423,0-6.362-1.228-8.817-3.683c-2.456-2.456-3.683-5.395-3.683-8.817V23.214c0-3.422,1.228-6.362,3.683-8.817 c2.455-2.456,5.394-3.683,8.817-3.683h17.857c3.422,0,6.362,1.228,8.817,3.683c2.455,2.455,3.683,5.395,3.683,8.817V25h37.5 c3.422,0,6.361,1.228,8.816,3.683C95.201,31.138,96.429,34.078,96.429,37.5z' /></svg>"); */
    background-position: center top;
    background-size: 75% auto;
  }

  .directory-list li.js:before {
    /* folder icon if folder class is specified */
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/165/165480.svg");
    background-position: center top;
    background-size: 75% auto;
  }

  .directory-list li.sql:before {
    /* folder icon if folder class is specified */
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/29/29594.svg");
    background-position: center top;
    background-size: 75% auto;
  }

  .directory-list li.json:before {
    /* folder icon if folder class is specified */
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/460/460989.svg");
    background-position: center top;
    background-size: 75% auto;
  }


  input.disabled{
    opacity:0.6;
    pointer-events:none
  }
  .vs-select__label{
    margin-bottom: 0;
  }
  .close-icon{
    position:absolute;
    top:5px;
    right:5px;
    font-size:20px;
    cursor: pointer;
  }
  .mainContainer{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    /* height: 100%; */
    background: #F2F8FF;
  }
  .mainContainer__parent{
    /* position: relative; */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .body-container{
    height: calc(100% + -85px);
    position: absolute;
    right: 0;
    top: 85px;
    /* top: 0px; */
    /* width: calc(100% - 50px); */
    width: 100%;
    text-align: left;
  }
  .left-part{
    border-right: 1px solid #C6D9FF;
    background: #F2F8FF;
    padding-top: 20px;
  }
  .right-part{
    background: #F2F8FF;
  }
  .header-title{
    color:#2B4D75;
    font-size:30px;
    font-weight: 600;
    /* margin:20px 0; */
    position:sticky;
    background: #F2F8FF;
    padding: 20px 20px 20px 0;
    margin: 0 -15px 20px;
    z-index: 20;
    top:0;
  }
  label{
    color:#2B4D75;
    font-size:16px;
  }
  input{
    border:1px solid #C6D9FF !important;
  }
  .card{
    border: 1px solid #C6D9FF !important;
  }
  .vs-select__input {
    background:white !important;
  }
  button{
    outline:none !important;
    display:inline-flex !important;
    margin:0 !important;
    /* margin-left:0; */
  }
  .vs-checkbox-label{
    margin-bottom:0;
  }
  input::placeholder{
    color:#9FB3CA !important;
  }

  .display-table{ display: inline-flex;height:100%;flex-direction: row;vertical-align:middle;}
  .display-table-cell{ align-self:center;vertical-align:middle;}
  .display-table-cell svg{flex-shrink:0;}
  /* .header-title i{
    color:black;
  } */
</style>
        