<template>
    <div class="w-100 h-100">
            <div class="row no-gutters w-100 h-100">
              <div class="col-sm-12 col-md-8 col-lg-9 left-part">
                <div class="container" >
                  <form >
                    <div class="form-group row">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Database</label>
                      <div class="col-sm-2">
                        <vs-checkbox v-model="data.database.create_database">
                            Create Database
                        </vs-checkbox>
                      </div>
                      <div class="col-sm-2">
                        <vs-checkbox v-model="data.database.create_tables">
                            Create Tables
                        </vs-checkbox>
                      </div>
                      <div class="col-sm-6">
                        <input type="text" v-model="data.database.name" class="form-control" id="inputEmail3" placeholder="database name">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="inputEmail3" class="col-sm-2 col-form-label">Models</label>
                      <div class="col-sm-10">
                        <div class="card position-relative mb-3" :class="data.models.length > 1 ?'pt-3':''" v-for="(model,indexModel) in data.models" :key="indexModel">
                          <div class="card-body">
                            <i class="bx bx-minus-circle close-icon" @click="removeModel(indexModel)"></i>
                            <div class="form-group row">
                              <label for="inputModelName" class="col-sm-1 col-form-label">Name</label>
                              <div class="col-sm-11">
                                <input type="text" v-model="model.model" class="form-control"  id="inputModelName" placeholder="Model Name">
                                <div class="w-100 mt-2" >
                                  <div class="row no-gutters">
                                    <div class="col-12 col-md-6">
                                      <vs-checkbox v-model="model.signIn">
                                        SignIn
                                      </vs-checkbox>
                                    </div>
                                    <div class="col-12 col-md-6">
                                      <vs-checkbox v-model="model.signUp">
                                        SignUp
                                      </vs-checkbox>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row mb-0">
                              <label for="inputModelName" class="col-sm-1 col-form-label">Fields</label>
                              <div class="col-sm-11">
                                
                                <div class="card mb-0" :class="indexField > 0 ? 'mt-2':''" v-for="(field,indexField) in model.fields" :key="indexField">
                                  <div class="card-body">
                                    <i class="bx bx-minus-circle close-icon"  @click="removeField(model,indexField)"></i>
                                    <div class="row">
                                      <div class='col-3 px-1'>
                                        <input type="text" v-model="field.name" class="form-control display-inline-block" id="inputModelName" placeholder="Field Name">
                                      </div>
                                      <div class='col-3 px-1'>
                                        <vs-select
                                          color="primary"
                                          placeholder="Select Type"
                                          class="display-inline-block background-white"
                                          v-model="field.type"
                                        >
                                          <vs-option label="Varchar" :value="'varchar'">
                                            Varchar
                                          </vs-option>
                                          <vs-option label="Int" :value="'int'">
                                            Int
                                          </vs-option>
                                          <vs-option label="Float" :value="'float'">
                                            Float
                                          </vs-option>
                                          <vs-option label="Boolean" :value="'boolean'">
                                            Boolean
                                          </vs-option>
                                          <vs-option label="Timestamp" :value="'timestamp'">
                                            Timestamp
                                          </vs-option>
                                        </vs-select>
                                      </div>
                                      <div class='col-3 px-1'>
                                        <input type="text" v-model="field.length" class="form-control display-inline-block" id="inputModelName" placeholder="Field length">
                                      </div>
                                      <!-- <div class='col-2 px-1'>
                                        <input type="text" v-model="field.validator.min" class="form-control display-inline-block" id="inputModelName" placeholder="Min">
                                      </div>
                                      <div class='col-2 px-1'>
                                        <input type="text" v-model="field.validator.max" class="form-control display-inline-block" id="inputModelName" placeholder="Max">
                                      </div> -->
                                      <div class='col-3 px-1'>
                                        <vs-checkbox v-model="field.validator.required">
                                          Required
                                        </vs-checkbox>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </div>
                                <vs-button
                                  class="float-right mt-3"
                                  size="large"
                                  gradient
                                  :active="active == 1"
                                  @click="active = 1"
                                  @click.native.prevent="addField(indexModel)"
                                >
                                  <i class="bx bx-plus mr-2"></i> Field
                                </vs-button>
                              </div>
                            </div>

                            <div class="form-group row mb-0 mt-4">
                              <label for="inputModelName" class="col-sm-2 col-form-label">Mapping</label>
                              <div class="col-sm-10">
                                
                                <div class="card mb-0" v-for="(map,indexMap) in model.mapping" :key="indexMap">
                                  <div class="card-body">
                                    <i class="bx bx-minus-circle close-icon" @click.stop="removeMapping(model,indexMap)"></i>
                                    <div class="row">
                                      <div class='col-3 px-1'>
                                        <vs-select placeholder="Select" v-model="map.map">
                                          <vs-option label="BelongsTo" value="belongsTo">
                                            BelongsTo
                                          </vs-option>
                                          <vs-option label="HasMany" value="hasMany">
                                            HasMany
                                          </vs-option>
                                          <vs-option label="HasOne" value="hasOne">
                                            HasOne
                                          </vs-option>
                                        </vs-select>
                                      </div>
                                      <div class='col-3 px-1'>
                                        <vs-select
                                          color="primary"
                                          placeholder="Select Model"
                                          class="display-inline-block background-white"
                                          v-model="map.model"
                                        >
                                          <vs-option v-for="(mdl,indexMdl) in listModels(model.model)" :key="indexMdl" :label="mdl" :value="mdl">
                                            {{mdl}}
                                          </vs-option>
                                        </vs-select>
                                      </div>
                                      <div class='col-3 px-1' v-if="map.map == 'belongsToMany'">
                                        <input type="text" class="form-control display-inline-block" v-model="map.throw" id="inputModelName" placeholder="Through">
                                      </div>
                                      <div class='col-3 px-1'>
                                        <input type="text" class="form-control display-inline-block" v-model="map.foreign_key" id="inputModelName" placeholder="Foreign Key">
                                      </div>
                                      
                                    </div>
                                  </div>
                                </div>
                                <vs-button
                                  class="float-right mt-3"
                                  size="large"
                                  gradient
                                  :active="active == 1"
                                  @click="active = 1"
                                  @click.native.prevent="addMapping(indexModel)"
                                >
                                  <i class="bx bx-plus mr-2"></i> Association
                                </vs-button>
                              </div>
                            </div>

                            <div class="form-group row mb-0 mt-4">
                              <label for="inputModelName" class="col-sm-2 col-form-label">Crud</label>
                              <div class="col-sm-10">
                                <div class="w-100 mt-2" >
                                  <div class="row no-gutters">
                                    <div class="col-12 col-md-3">
                                      <vs-checkbox v-model="model.crud.create">
                                        Create
                                      </vs-checkbox>
                                    </div>
                                    <div class="col-12 col-md-3">
                                      <vs-checkbox v-model="model.crud.read">
                                        Read
                                      </vs-checkbox>
                                    </div>
                                    <div class="col-12 col-md-3">
                                      <vs-checkbox v-model="model.crud.update">
                                        Update
                                      </vs-checkbox>
                                    </div>
                                    <div class="col-12 col-md-3">
                                      <vs-checkbox v-model="model.crud.delete">
                                        Delete
                                      </vs-checkbox>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class='w-100 mt-4 justify-content-end text-right'>
                          <vs-button
                            class=""
                            size="large"
                            :active="active == 1"
                            @click="active = 1"
                            @click.native.prevent="addModel()"
                          >
                            <i class="bx bx-plus mr-2"></i> New Model
                          </vs-button>
                        </div>
                        
                      </div>
                    </div>                    
                  </form>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-3 right-part">
                <div class="container" >
                  <div class="header-title pl-4">
                    <i class='bx bx-file'></i>
                    Files Structure
                  </div>

                  <ul class="directory-list">
                    <li class="folder">controllers
                      <ul>
                        <li class="js">mainController.js</li>
                        <li class="js" v-for="(file,indexFile) in listModelsToCreate" :key="indexFile">{{file+'Controller.js'}}</li>
                      </ul>
                    </li>
                    <li class="folder">middleware
                      <ul>
                        <li class="js">web.js</li>
                      </ul>
                    </li>
                    <li class="folder">models
                      <ul>
                        <li class="js">index.js</li>
                        <li class="js" v-for="(file,indexFile) in listModelsToCreate" :key="indexFile">{{file+'.js'}}</li>
                      </ul>
                    </li>
                    <li class="folder">routes
                      <ul>
                        <li class="js">web.js</li>
                        <li class="js" v-for="(file,indexFile) in listModelsToCreate" :key="indexFile">{{file+'.js'}}</li>
                      </ul>
                    </li>
                    <li class="sql">db.sql</li>
                    <li class="json">package.json</li>
                    <li class="js">server.js</li>
                  </ul>
                </div>
                <div style="margin: 1rem">
                  <vs-button
                          class="w-100 mt-4"
                          size="xl"
                          color="rgb(59,222,200)"
                          gradient
                          :loading="loadingGenerateDb"
                          :active="active == 1"
                          @click="active = 1"
                          @click.native.prevent="generateFile()"
                        >
                          <i class="bx bx-play mr-2"></i> Generate
                        </vs-button>
                </div>
                
              </div>
              
              
            </div>
          </div>
</template>
<script>
    import axios from 'axios'
    export default {
      data:() => ({
        active: 'home',
        loadingGenerateDb:false,
        //apiURL:"https://eazyappi.herokuapp.com/generate",
        apiURL:"https://mockup-api-production.up.railway.app/generate",        

        data:{
          database:{
            create_database : true,
            create_tables : true,
            name : ''
          },
          
          models:[{
            model:"user",
            signIn:true,
            signUp:true,
            crud : {
                create : true,
                read   : true,
                update : true,
                delete : true,
              },
            fields:[
              {
                name:"name",
                type:'varchar',
                length:255,
                validator:{
                  min:0,
                  max:255,
                  required:false
                }
              },
              {
                name:"email",
                type:'varchar',
                length:255,
                validator:{
                  min:0,
                  max:255,
                  required:false
                }
              },
              {
                name:"password",
                type:'varchar',
                length:255,
                validator:{
                  min:0,
                  max:255,
                  required:false
                }
              },
              {
                name:"created_at",
                type:'timestamp',
                length:null,
                default : null,
                validator:{
                  min:0,
                  max:null,
                  required:false
                }
              },
              {
                name:"updated_at",
                type:'timestamp',
                length:null,
                default : null,
                validator:{
                  min:0,
                  max:null,
                  required:false
                }
              }
            ],
            mapping:[]
          }]
        }
      }),
      computed:{
        listModelsToCreate(){
          let finalList = []
          for(let i=0;i<this.data.models.length;i++){
            if(this.data.models[i].model !== ""){
              finalList.push(this.data.models[i].model);
            }
            else{
              finalList.push("noName");
            }
          }
          return finalList
        },
      },
      methods:{
       download(url) {
          console.log('link',url)
          var element = document.createElement('a');
          element.setAttribute('href',url);
          element.setAttribute('download','download');
          console.log('element',element)
          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        },
        listModels(mdl){
          let finalList = []
          for(let i=0;i<this.data.models.length;i++){
            if(this.data.models[i].model !== mdl){
              finalList.push(this.data.models[i].model);
            }
          }
          if(!finalList.length){
            finalList.push('');
          }
          return finalList
        },
        removeField(model,indexField){
          model.fields.splice(indexField,1);
        },
        changeMap(event,model,indexMap){
          model.mapping[indexMap].map = event
          if(event !== 'belongsToMany'){
            model.mapping[indexMap].throw = null
          }
        },
        removeMapping(model,indexMap){
          model.mapping.splice(indexMap,1);
        },
        removeModel(indexModel){
          this.data.models.splice(indexModel,1);
        },
        isValidObject(){
          return []
        },
        addModel(){
          let errors = this.isValidObject()
          if(!errors.length){
            this.data.models.push({
              model:"",
              signIn:false,
              signUp:false,
              crud : {
                create : true,
                read   : true,
                update : true,
                delete : true,
              },
              
              fields:[
                {
                  name:"",
                  type:'int',
                  length:255,
                  validator:{
                    min:0,
                    max:255,
                    required:false
                  }
                },
                {
                name:"created_at",
                type:'timestamp',
                length:null,
                default : null,
                validator:{
                  min:0,
                  max:null,
                  required:false
                }
              },
              {
                name:"updated_at",
                type:'timestamp',
                length:null,
                default : null,
                validator:{
                  min:0,
                  max:null,
                  required:false
                }
              }
              ],
              mapping:[]
            })
          }
          else{
            console.log("something needed")
          }
        },
        addField(indexMod){
          this.data.models[indexMod].fields.push({
            name:"",
            type:'int',
            length:255,
            validator:{
              min:0,
              max:255,
              required:false
            }
          })
        },
        addMapping(indexMod){
          this.data.models[indexMod].mapping.push({
            map:"belongsTo",
            model:'',
            throw:null,
            foreign_key:null
          })
        },
        generateFile(){
          if(this.data.database.name == ""){
              this.openNotification('bottom-center', 'danger','please fill all required fields',' ')
              return 
          }

          for(let i = 0;i < this.data.models.length; i++){
            if(this.data.models[i].model == ""){
              this.openNotification('bottom-center', 'danger','please fill all required fields',' ')
              return 
            }
            for(let j = 0; j < this.data.models[i].fields.length; j++){
              if(this.data.models[i].fields[j].name == ""){
                 this.openNotification('bottom-center', 'danger','please fill all required fields',' ')
                 return 
              }
            }
          }
          this.loadingGenerateDb = true
          axios.post(this.apiURL,this.data)
          .then(res=>{
            this.loadingGenerateDb = false
            this.download(res.data);
            this.openNotification('bottom-center', 'success','Api genererated successfully',' ')
          }).catch(err=>{
            this.loadingGenerateDb = false
            this.openNotification('bottom-center', 'danger')
            console.log("catched error is",err)
          })
        },
        openNotification(position = null, color,title = null,text = null) {
          title = title ? title : 'Oops something went wrong !'
          text = text ? text : 'We catched the error we will fix it asap'
          this.$vs.notification({
            duration : '6000',
            progress: 'auto',
            color,
            position,
            title,
            text
          })
        }
      }
    }
</script>